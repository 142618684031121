.hero__narrow {
    padding: var(--spacing-global) 0;
    display: flex;
    min-height: 10rem;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/bridge.jpg');
    background-position: center;
    justify-content: center;
    align-items: center;

}

.hero__narrow.hero__narrow--left {
    justify-content: flex-start;
    padding: var(--spacing-global);

    .hero__body {
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 0;
        text-align: left;

        max-width: 768px;
        margin: 0 auto;
    }
}

.hero__narrow.hero__narrow--right {
    justify-content: flex-end;
    padding: var(--spacing-global);

    .hero__body {
        justify-content: flex-end;
        align-items: flex-end;
    }
}



.hero__narrow.hero__narrow--small {
    .hero__body h1 {
        font-size: 2rem;
    }

}

.hero__narrow .hero__body {
    max-width: initial;
    color: var(--color-brand-alternative);
    text-align: center;
    display: FLEX;
    flex-direction: column;
    align-items: center;
    justify-content: CENTER;

    h2 {
        color: white;
    }
}

.hero__narrow .hero__body h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin: 0;
}

.hero__narrow .hero__body p {
    font-family: Roboto;
    font-size: 1.125rem !important;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    font-size: 1rem;
    margin: 1rem 0 0 0;
    max-width: 768px;
    padding: 0 1rem;
}

.hero__narrow .hero__body a {
    max-width: 7rem;
    margin-top: 2rem
}

@media screen and (max-width: 990px) {


    .hero__narrow.hero__mobile-simple {
        background-image: none;

        h2,
        p{
            color: #333;
        }

    }
}