.footer {
    padding: 0rem var(--spacing-global);
    background: var(--color-brand-alternative);
    margin-top: 2rem;
}

.footer__inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
    font-size: 0.8rem;
    flex-wrap: wrap;

    section {
        display: flex;
        justify-content: left;
        align-items: flex-start;
        padding: 0rem var(--spacing-global);
    }

    a {
        padding-right: 2rem;
        font-size: 0.8rem;
    }
}

.footer__wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
}

@media (max-width: 768px) {
    .footer__inner {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        a{
            padding: 0.8rem 0;
        }
    }
}