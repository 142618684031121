

.accordion {
    border-top: solid 1px var(--color-link-primary);

    ul,
    li {
        padding: 0;
        margin: 0;
    }

    li {
        border-bottom: solid 1px var(--color-link-primary);
    }

    .accordion__title {
        font-size: 1.125rem;
        font-weight: 700;
        margin: 1.25rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.accordion__item {

    .accordion__body {
        overflow: hidden;
        transition: max-height 0.4s ease-in-out;
        p{
            padding: 0.5rem 0;
        }
    }

    &--closed {
        .accordion__body {
            max-height: 0;
        }
    }

    &--open {
        .accordion__body {
            max-height: 15rem;
        }
    }
}