.input__wrapper{
    border: solid 1px #222;
    display: inline-block;
    input {
        border: none;
        padding: 0.5rem;
        font-size: 1.2rem;
        width: 100%;
        box-sizing: border-box;
        height: 100%;
    }
}