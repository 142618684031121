.contactform {
    display: block;



    label {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        flex-wrap: wrap;

        &.label-error{
            color: var(--color-error);

            span{
                color: inherit;
            }

            input,
            textarea{
                border-color: var(--color-error);
            }
        }

        &.checkbox {
            flex-direction: row;
            align-items: center;

            span {
                padding: 0 0 0 0.5rem;
            }
        }

        .error {
            display: block;
            flex-basis: 100%;
            padding-top: 0.5rem;
            font-size: 0.8rem;
            color: var(--color-error);
        }
    }

    span {
        font-size: 1.0rem;
        display: block;
        padding-bottom: 0.25rem;
        color: var(--color-link-primary);
    }

    textarea,
    input {
        display: block;
        padding: 0.7rem;
        border: solid 1px var(--color-brand);
        font-size: 1.0rem;
        border-radius: var(--radius);
    }
}

.loader-wrapper {
    display: flex;
    justify-content: center;
}