.terms {

    padding: var(--spacing-global);

    h2 {
        font-size: 3.0rem;
    }

    h3 {
        font-size: 2.5rem;
    }
}