.carousel {
    padding-top: var(--spacing-global);
    padding-left: 1rem;
    overflow-x: hidden;
    position: relative;
    z-index: 6;
    margin-left: -10px;

    ul {
        width: 100vw;
        display: flex;
        overflow-x: scroll;
        scroll-behavior: smooth;
        /* use this property to hide the scrollbar on firefox */
        scrollbar-width: none;
        gap: 1rem;
        padding: 2rem 1rem;
        scroll-snap-type: x mandatory;
    }


    &__item {
        flex-basis: 15%;
        min-width: 20rem;
        flex-shrink: 0;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        scroll-snap-align: start;

        background: rgba(0, 0, 0, 0.4);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);

        /* Subtle white border */
        p {
            font-weight: 700;
            text-align: center;
            color: white;
        }

        .light {
            font-weight: 100;
            font-size: 1rem;
        }

        &:hover {
            transform: scale(1.008);
            transition: transform 0.2s ease-in-out, box-shadow 0.2s linear;
            box-shadow: 0px 0px 36px -4px rgba(0, 0, 0, 0.45);
        }

        a {
            margin: 0;
        }

        &.skeleton {

            p {
                min-width: 200px;
                min-height: 20px;
                background: linear-gradient(90deg, #ddda 20%, #efefefaa, #ddda 40%) right / 300% 100%;
                animation: skeleton-loading 1.5s linear infinite;
                border-radius: 20px;
                opacity: 0.5;
                &:nth-child(2){
                    min-width: 180px;
                }
            }
            a{
                pointer-events: none;
                background: linear-gradient(90deg, #ddda 20%, #efefefaa, #ddda 40%) right / 300% 100%;
                animation: skeleton-loading 1.5s linear infinite;
                border:none;
                border-radius: 10px;
                width: 45px;
                height: 18px;
                opacity: 0.5;
            }

        }
    }
}

@keyframes skeleton-loading {
    to {
      background-position: left;
    }
  }
  

.carousel::-webkit-scrollbar {
    display: none;
}