.availableJobs{
    padding: var(--spacing-global) var(--spacing-global);
    transition: padding 0.3s linear;

    max-width: 768px;
    margin: 0 auto;
}

.availableJobs--true{
    transition: padding 0.3s linear;
    padding: var(--padding-large) var(--padding-large);
    text-align: center;
}

.availableJobs.availableJobs__loader--true{
  padding: 0;
}

.availableJobs h2{
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
    text-align: left;
}
  

  .ideas{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    a{
      border: solid 1px var(--color-neutral);
      border-radius: var(--radius);
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #222;
    }
  }