@media (max-width: 768px) {
    .hero__large.hero__large--home{
        min-height: initial;
    }
}

.hero__large.hero__large--home{
    min-height: 45rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
}

.hero__large {

    padding: 0 var(--spacing-global);
    display: flex;
    min-height: 40rem;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/clouds.jpg');
    background-position: center;
    justify-content: flex-start;
    align-items: center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    position: relative;



    /* &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.0)), url('/public/rays.gif');
        background-repeat: no-repeat;
        background-size: cover;
        mix-blend-mode: color-dodge;
        transform: scaleX(-1);
        z-index: 2;
    } */
}

.hero__body {
    color: var(--color-brand-alternative);
    max-width: 35rem;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
}

.hero__body h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin: 0;
    mix-blend-mode: color-dodge;
}

.hero__body p {
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    font-size: 1rem;
    margin: var(--spacing-global) 0 0 0;
   
}

.hero__body a {

    max-width: 7rem;
    margin-top: var(--spacing-global);
}