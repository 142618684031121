header.navbar {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 5px;
  background: var(--color-brand);

  .navbar__container {


    .navbar__basket{
      display: none;
      position: absolute;
      transform: translateX(-120%) translateY(2px);
    }

    display: flex;
    height: 4.5rem;
    padding: 0rem var(--spacing-global);
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 5;

    top: 0;
    //view-transition-name: disabled;
    max-width: 768px;
    margin: 0 auto;


    header a {
      font-weight: 600;
      padding: 1rem;
      margin-right: 1rem;
    }

    a {

      font-size: 1rem;
      padding: 1rem;
      color: var(--color-link-primary);
      text-decoration: none;
      border-bottom: solid 4px transparent;
      transition: border-bottom 0.3s ease-in-out;
      color: white;

      &.active {
        border-bottom: solid 4px white;
      }

      &:hover:not(.active) {
        border-color: #ccc;
      }
    }



    .navbar__logo {
      width: 50px;
      height: 50px;
      object-fit: cover;
      text-align: right;
      background: white;
      position: relative;
      border-radius: 50%;
      border: solid 2px #ccc;
      padding: 0.5rem;;
      box-sizing: border-box;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;;
      }
    }

    .navbar__links {
      display: flex;

      a {
        color: white;;
        border-radius: 0;
      }
    }

    .navbar__links {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 70px;
      left: 0;
      transform: translateY(-150%);
      /* Initially hide the menu */
      opacity: 0;
      transition: all 0.3s ease-in-out;
      /* Add a transition effect */
      width: 100%;
      /* Adjust the width as needed */
      height: auto;
      /* Adjust the height as needed */
      z-index: 100;
      /* Ensure the menu is on top */
    }

    .navbar__links a {
      display: block;
      padding: 1rem;
      text-decoration: none;
      color: white;;
    }

    .navbar__links.active {
      opacity: 1;
      transform: translateY(0%);
      /* Show the menu when active */
    }

    /* Media query for larger screens */
    @media (min-width: 768px) {
      .navbar__links {
        position: static;
        /* Remove fixed positioning */
        transform: translateX(0%);
        /* Show the menu */
        flex-direction: row;
        /* Display links horizontally */
        width: auto;
        /* Reset width */
        opacity: 1;
      }

      .navbar__links a {
        padding: 0.5rem 1rem;
      }

      .navbar__links.active {
        opacity: 1;
        transform: translateY(0%);
        /* Show the menu when active */
      }

      .burger-button {
        display: none !important;
      }
    }

    /* Basic styling for the burger button */
    .burger-button {
      display: block;
      position: relative;
      width: 30px;
      height: 24px;
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 0;
      z-index: 101;
      /* Ensure the button is above the menu */
    }

    /* Style the burger bars */
    .burger-button span {
      display: block;
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #333;
      border-radius: 2px;
      transition: all 0.3s ease-in-out;
    }

    /* Position the burger bars */
    .burger-button span:nth-child(1) {
      top: 5px;
    }

    .burger-button span:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    .burger-button span:nth-child(3) {
      bottom: 5px;
    }

    /* Styling for the active state (menu open) */
    .burger-button.active span:nth-child(1) {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    .burger-button.active span:nth-child(2) {
      opacity: 0;
    }

    .burger-button.active span:nth-child(3) {
      bottom: 50%;
      transform: translateY(50%) rotate(-45deg);
    }
  }


}

@media (min-width: 768px) {
  body .navbar__mobile {
    display: none;
  }
}

@media (max-width: 768px) {

  body header.navbar {
    display: none;
  }
}


.navbar__mobile {
  height: 80px;
  left: 0;
  right: 0;
  background: white;
  bottom: 0;
  position: fixed;
  z-index: 6;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 7px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  view-transition-name: disabled;

  a {
    padding: 0.3rem;
    background-color: transparent;
    box-shadow: inset rgba(0, 0, 0, 0.5) 0px 0 0 0;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    flex:1;

    span {
      color: var(--color-brand);
    }

    svg {
      fill: var(--color-brand) !important;
    }
  }

  a.active {

    border-bottom: solid 4px var(--color-brand);
    ;



  }
}