body {
  margin: 0;
  font-family: Roboto;
  font-size: 62.5%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-out {
  to {
    opacity: 1;
    transform: translateX(-100%);
  }
}

@keyframes slide-in {
  from {
    opacity: 1;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out-reverse {
  to {
    opacity: 1;
    transform: translateX(100%);
  }
}

@keyframes slide-in-reverse {
  from {
    opacity: 1;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

html::view-transition-old(root) {
  animation: .3s slide-out;
}

html::view-transition-new(root) {
  animation: .3s slide-in;
}

html.exit::view-transition-old(root) {
  animation: .3s slide-out-reverse;
}

html.exit::view-transition-new(root) {
  animation: .3s slide-in-reverse;
}

:root {
  --color-brand: #0e682d;
  --color-error: #ab0000;
  --color-brand-alternative: #fff;
  --color-link-primary: #222;
  --color-neutral: #eee;
  --spacing-global-double: 7rem;
  --spacing-global: 4rem;
  --padding-large: 2rem;
  --radius: 10px;
}

a {
  font-size: 1rem;
  text-decoration: none;
}

a, input, .job__date, .input__wrapper, button {
  border-radius: var(--radius);
}

@media screen and (width <= 990px) {
  :root {
    --spacing-global: 1.25rem;
    --spacing-global-double: 3rem;
    --padding-large: .75Ìrem;
  }
}

.brand {
  border: 1px solid var(--color-brand, #0e682d);
  background: var(--color-brand, #0e682d);
  color: var(--color-brand-alternative);
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .75rem 1.5rem;
  display: flex;
}

.brand.brand--small {
  padding: .5rem 1rem;
}

a.brand {
  border: 1px solid var(--color-brand, #0e682d);
  background: var(--color-brand, #0e682d);
  color: var(--color-brand-alternative);
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .75rem 1.5rem;
  display: flex;
}

a.brand__alternative {
  color: #222;
  background: none;
  border: 1px solid #222;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .75rem 1.5rem;
  transition: background .3s ease-in-out;
  display: flex;
}

.brand__white {
  background: #fff;
  border: 1px solid #222;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .55rem 1.5rem;
  transition: background .3s ease-in-out;
  display: flex;
  color: #222 !important;
  font-size: 1rem !important;
}

a.brand__alternative:hover {
  background: var(--color-brand, #0e682d);
  color: #fff;
  transition: background .3s ease-in-out;
}

button.brand {
  border: 1px solid var(--color-brand, #0e682d);
  background: var(--color-brand, #0e682d);
  color: var(--color-brand-alternative);
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: .75rem 1.5rem;
  display: flex;
}

p {
  font-size: 1.125rem;
  font-weight: 300;
}

.f-r {
  justify-content: flex-end;
  display: flex;
}

h1 {
  margin: 0;
  font-size: 3.5rem;
  font-weight: 700;
}

h2 {
  color: var(--color-link-primary);
  font-size: 3rem;
}

h3 {
  color: var(--color-link-primary);
  font-size: 2.5rem;
}

@media (width <= 768px) {
  #main-content {
    padding-bottom: 70px;
  }
}

.cookie__inline {
  gap: 1rem;
  padding-right: 1rem;
  display: flex;
}

.cookie__inline button {
  font-size: 1rem !important;
}

@media (width <= 768px) {
  .cookie__inline {
    justify-content: center;
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
  }
}

.availableJobs {
  padding: var(--spacing-global) var(--spacing-global);
  max-width: 768px;
  margin: 0 auto;
  transition: padding .3s linear;
}

.availableJobs--true {
  padding: var(--padding-large) var(--padding-large);
  text-align: center;
  transition: padding .3s linear;
}

.availableJobs.availableJobs__loader--true {
  padding: 0;
}

.availableJobs h2 {
  text-align: left;
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
}

.ideas {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem;
  display: flex;
}

.ideas a {
  border: solid 1px var(--color-neutral);
  border-radius: var(--radius);
  color: #222;
  justify-content: center;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
}

.lds-facebook {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-facebook div {
  background: #222;
  width: 16px;
  animation: 1.2s cubic-bezier(0, .5, .5, 1) infinite lds-facebook;
  display: inline-block;
  position: absolute;
  left: 8px;
}

.lds-facebook div:first-child {
  animation-delay: -.24s;
  left: 8px;
}

.lds-facebook div:nth-child(2) {
  animation-delay: -.12s;
  left: 32px;
}

.lds-facebook div:nth-child(3) {
  animation-delay: 0;
  left: 56px;
}

@keyframes lds-facebook {
  0% {
    height: 64px;
    top: 8px;
  }

  50%, 100% {
    height: 32px;
    top: 24px;
  }
}

.grid {
  padding: var(--spacing-global-double) 30px;
}

.grid__inner {
  column-gap: var(--spacing-global);
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.grid__content {
  padding: var(--spacing-global) 0;
  flex-direction: column;
  display: flex;
}

.grid__image {
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("train.af9795e1.jpg");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 30px;
}

.grid h2 {
  margin-top: 0;
  font-size: 2.5rem;
}

@media screen and (width <= 990px) {
  .grid__inner {
    display: block;
  }

  .grid__image {
    min-height: 20rem;
  }
}

.hero__narrow {
  padding: var(--spacing-global) 0;
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("bridge.9623a47e.jpg");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  display: flex;
}

.hero__narrow.hero__narrow--left {
  padding: var(--spacing-global);
  justify-content: flex-start;
}

.hero__narrow.hero__narrow--left .hero__body {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 768px;
  margin: 0 auto;
  padding-left: 0;
}

.hero__narrow.hero__narrow--right {
  padding: var(--spacing-global);
  justify-content: flex-end;
}

.hero__narrow.hero__narrow--right .hero__body {
  justify-content: flex-end;
  align-items: flex-end;
}

.hero__narrow.hero__narrow--small .hero__body h1 {
  font-size: 2rem;
}

.hero__narrow .hero__body {
  max-width: initial;
  color: var(--color-brand-alternative);
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero__narrow .hero__body h2 {
  color: #fff;
}

.hero__narrow .hero__body h1 {
  margin: 0;
  font-size: 3.5rem;
  font-weight: 700;
}

.hero__narrow .hero__body p {
  max-width: 768px;
  margin: 1rem 0 0;
  padding: 0 1rem;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  font-size: 1.125rem !important;
}

.hero__narrow .hero__body a {
  max-width: 7rem;
  margin-top: 2rem;
}

@media screen and (width <= 990px) {
  .hero__narrow.hero__mobile-simple {
    background-image: none;
  }

  .hero__narrow.hero__mobile-simple h2, .hero__narrow.hero__mobile-simple p {
    color: #333;
  }
}

.input__wrapper {
  border: 1px solid #222;
  display: inline-block;
}

.input__wrapper input {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 100%;
  padding: .5rem;
  font-size: 1.2rem;
}

.search {
  background: var(--color-brand-alternative);
  z-index: 5;
  padding: 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-shadow: 0 8px 5px #959da533;
}

.search__inner {
  grid-template-columns: 1fr 130px;
  gap: 1rem;
  max-width: 768px;
  margin: 0 auto;
  display: grid;
}

.search button {
  font-size: 1rem;
  font-weight: 400;
}

.search__clear {
  background: var(--color-brand-alternative);
  border: solid 1px var(--color-neutral);
  border-radius: 5px;
  border-radius: var(--radius);
  font-size: .8rem;
  transition: opacity .3s;
  position: absolute;
  top: 26px;
  right: 167px;
}

.search__clear--hidden {
  pointer-events: none;
  opacity: 0;
}

@media screen and (width >= 769px) {
  .search__inner {
    grid-template-columns: 1fr 100px 130px;
  }

  .search__clear {
    right: initial;
    top: inherit;
    position: relative;
  }

  .search__clear--hidden {
    pointer-events: none;
    opacity: .2;
  }
}

@media screen and (width >= 991px) {
  .search {
    z-index: 6;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.job__list {
  padding: 0 var(--spacing-global);
  max-width: 768px;
  margin: 0 auto;
}

.job__item {
  border-bottom: 1px solid #222;
  padding: .5rem;
}

.job__row {
  column-gap: var(--spacing-global);
  grid-template-columns: 1fr 150px;
  align-items: flex-start;
  margin: 2rem 0 1rem;
  display: grid;
}

.job__row--end {
  align-items: flex-end;
}

.job__row--end .brand--small {
  position: relative;
  top: .5rem;
  right: -.5rem;
}

.job__title {
  color: #222;
  align-self: center;
  align-items: center;
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 700;
  display: flex;
}

.job__date {
  background: var(--color-neutral);
  text-align: center;
  padding: .625rem;
  font-size: .875rem;
  font-style: normal;
  font-weight: 600;
}

.job__salary {
  color: #222;
  margin-top: 0;
  font-size: 1rem;
  font-weight: 400;
}

.job__description {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  max-height: 4rem;
  display: flex;
  overflow: hidden;
}

.job__info {
  grid-template-columns: 1fr 1fr 150px;
  padding-bottom: 1rem;
  display: grid;
}

.job__location {
  align-items: center;
  padding-right: 2rem;
  font-size: 1rem;
  font-weight: 300;
  display: flex;
}

.job__location--apply {
  padding-right: 0;
  display: block;
}

.brand__alternative {
  max-width: 6rem;
  margin-bottom: 1rem;
}

@media screen and (width <= 520px) {
  .job__info {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    display: grid;
  }

  .job__location {
    justify-content: flex-start;
    width: 100%;
  }

  .job__location--apply {
    grid-column: span 2;
    padding-top: 2rem;
  }

  .job__location--apply a {
    max-width: none;
  }
}

.paginator {
  padding: var(--spacing-global);
  flex-flow: wrap;
  flex: 1 1 0;
  justify-content: center;
  gap: .5rem;
  margin: 2rem 0;
  display: flex;
}

.paginator li {
  margin: 0;
}

.paginator li button {
  border: solid 1px var(--color-brand);
  cursor: pointer;
  background: #fff;
  padding: .25rem .5rem;
  font-size: 1rem;
  font-weight: 400;
  transition: transform .1s ease-in-out, background .3s linear;
}

.paginator li button:hover {
  background: var(--color-neutral);
  transform: scale(1.1);
}

.paginator li button.current {
  background: var(--color-brand);
  color: #fff;
}

.footer {
  padding: 0rem var(--spacing-global);
  background: var(--color-brand-alternative);
  margin-top: 2rem;
}

.footer__inner {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0;
  font-size: .8rem;
  display: flex;
}

.footer__inner section {
  padding: 0rem var(--spacing-global);
  justify-content: left;
  align-items: flex-start;
  display: flex;
}

.footer__inner a {
  padding-right: 2rem;
  font-size: .8rem;
}

.footer__wrapper {
  justify-content: space-between;
  padding-bottom: 1rem;
  display: flex;
}

@media (width <= 768px) {
  .footer__inner {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .footer__inner a {
    padding: .8rem 0;
  }
}

header.navbar {
  background: var(--color-brand);
  box-shadow: 0 8px 5px #959da533;
}

header.navbar .navbar__container {
  height: 4.5rem;
  padding: 0rem var(--spacing-global);
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  max-width: 768px;
  margin: 0 auto;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

header.navbar .navbar__container .navbar__basket {
  display: none;
  position: absolute;
  transform: translateX(-120%)translateY(2px);
}

header.navbar .navbar__container header a {
  margin-right: 1rem;
  padding: 1rem;
  font-weight: 600;
}

header.navbar .navbar__container a {
  color: #fff;
  border-bottom: 4px solid #0000;
  padding: 1rem;
  font-size: 1rem;
  text-decoration: none;
  transition: border-bottom .3s ease-in-out;
}

header.navbar .navbar__container a.active {
  border-bottom: 4px solid #fff;
}

header.navbar .navbar__container a:hover:not(.active) {
  border-color: #ccc;
}

header.navbar .navbar__container .navbar__logo {
  object-fit: cover;
  text-align: right;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: .5rem;
  position: relative;
}

header.navbar .navbar__container .navbar__logo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

header.navbar .navbar__container .navbar__links {
  display: flex;
}

header.navbar .navbar__container .navbar__links a {
  color: #fff;
  border-radius: 0;
}

header.navbar .navbar__container .navbar__links {
  opacity: 0;
  z-index: 100;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 70px;
  left: 0;
  transform: translateY(-150%);
}

header.navbar .navbar__container .navbar__links a {
  color: #fff;
  padding: 1rem;
  text-decoration: none;
  display: block;
}

header.navbar .navbar__container .navbar__links.active {
  opacity: 1;
  transform: translateY(0%);
}

@media (width >= 768px) {
  header.navbar .navbar__container .navbar__links {
    opacity: 1;
    flex-direction: row;
    width: auto;
    position: static;
    transform: translateX(0%);
  }

  header.navbar .navbar__container .navbar__links a {
    padding: .5rem 1rem;
  }

  header.navbar .navbar__container .navbar__links.active {
    opacity: 1;
    transform: translateY(0%);
  }

  header.navbar .navbar__container .burger-button {
    display: none !important;
  }
}

header.navbar .navbar__container .burger-button {
  cursor: pointer;
  z-index: 101;
  background: none;
  border: none;
  width: 30px;
  height: 24px;
  padding: 0;
  display: block;
  position: relative;
}

header.navbar .navbar__container .burger-button span {
  background-color: #333;
  border-radius: 2px;
  width: 100%;
  height: 3px;
  transition: all .3s ease-in-out;
  display: block;
  position: absolute;
}

header.navbar .navbar__container .burger-button span:first-child {
  top: 5px;
}

header.navbar .navbar__container .burger-button span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

header.navbar .navbar__container .burger-button span:nth-child(3) {
  bottom: 5px;
}

header.navbar .navbar__container .burger-button.active span:first-child {
  top: 50%;
  transform: translateY(-50%)rotate(45deg);
}

header.navbar .navbar__container .burger-button.active span:nth-child(2) {
  opacity: 0;
}

header.navbar .navbar__container .burger-button.active span:nth-child(3) {
  bottom: 50%;
  transform: translateY(50%)rotate(-45deg);
}

@media (width >= 768px) {
  body .navbar__mobile {
    display: none;
  }
}

@media (width <= 768px) {
  body header.navbar {
    display: none;
  }
}

.navbar__mobile {
  z-index: 6;
  view-transition-name: disabled;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  height: 80px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 7px #0009;
}

.navbar__mobile a {
  background-color: #0000;
  border-radius: 0;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: .3rem;
  transition: all .3s ease-in-out;
  display: flex;
  box-shadow: inset 0 0 #00000080;
}

.navbar__mobile a span {
  color: var(--color-brand);
}

.navbar__mobile a svg {
  fill: var(--color-brand) !important;
}

.navbar__mobile a.active {
  border-bottom: solid 4px var(--color-brand);
}

@media (width <= 768px) {
  .hero__large.hero__large--home {
    min-height: initial;
  }
}

.hero__large.hero__large--home {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 45rem;
  overflow: hidden;
}

.hero__large {
  padding: 0 var(--spacing-global);
  z-index: 1;
  background-color: #0000;
  background-image: linear-gradient(#00000080, #00000080), url("clouds.54e0023e.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  justify-content: flex-start;
  align-items: center;
  min-height: 40rem;
  display: flex;
  position: relative;
}

.hero__body {
  color: var(--color-brand-alternative);
  box-sizing: border-box;
  z-index: 3;
  max-width: 35rem;
  padding: 1rem;
  position: relative;
}

.hero__body h1 {
  mix-blend-mode: color-dodge;
  margin: 0;
  font-size: 3.5rem;
  font-weight: 700;
}

.hero__body p {
  margin: var(--spacing-global) 0 0 0;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.hero__body a {
  max-width: 7rem;
  margin-top: var(--spacing-global);
}

.carousel {
  padding-top: var(--spacing-global);
  z-index: 6;
  margin-left: -10px;
  padding-left: 1rem;
  position: relative;
  overflow-x: hidden;
}

.carousel ul {
  scroll-behavior: smooth;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  gap: 1rem;
  width: 100vw;
  padding: 2rem 1rem;
  display: flex;
  overflow-x: scroll;
}

.carousel__item {
  scroll-snap-align: start;
  -webkit-backdrop-filter: blur(5px);
  background: #0006;
  border-radius: 16px;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 15%;
  justify-content: space-between;
  align-items: center;
  min-width: 20rem;
  padding: 1rem;
  display: flex;
}

.carousel__item p {
  text-align: center;
  color: #fff;
  font-weight: 700;
}

.carousel__item .light {
  font-size: 1rem;
  font-weight: 100;
}

.carousel__item:hover {
  transition: transform .2s ease-in-out, box-shadow .2s linear;
  transform: scale(1.008);
  box-shadow: 0 0 36px -4px #00000073;
}

.carousel__item a {
  margin: 0;
}

.carousel__item.skeleton p {
  opacity: .5;
  background: linear-gradient(90deg, #ddda 20%, #efefefaa, #ddda 40%) 100% / 300% 100%;
  border-radius: 20px;
  min-width: 200px;
  min-height: 20px;
  animation: 1.5s linear infinite skeleton-loading;
}

.carousel__item.skeleton p:nth-child(2) {
  min-width: 180px;
}

.carousel__item.skeleton a {
  pointer-events: none;
  opacity: .5;
  background: linear-gradient(90deg, #ddda 20%, #efefefaa, #ddda 40%) 100% / 300% 100%;
  border: none;
  border-radius: 10px;
  width: 45px;
  height: 18px;
  animation: 1.5s linear infinite skeleton-loading;
}

@keyframes skeleton-loading {
  to {
    background-position: 0;
  }
}

.carousel::-webkit-scrollbar {
  display: none;
}

.hero__home {
  border: 30px solid #fff;
  margin-bottom: -30px;
  position: relative;
}

.hero__home img {
  object-fit: cover;
  object-position: center;
  z-index: 1;
  border-radius: 30px;
  width: 100%;
  height: calc(100vh - 132px);
  position: relative;
}

@media (width <= 768px) {
  .hero__home img {
    height: calc(var(--vh, 1vh) * 100 - 72px);
    height: calc(100vh - 152px);
  }
}

.hero__home .overlay {
  content: "";
  z-index: 2;
  background-color: #00000080;
  border-radius: 30px;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 2px;
  left: 0;
}

.hero__home .body__wrapper {
  z-index: 3;
  width: 100%;
  padding-top: 4rem;
  position: absolute;
}

@media (width <= 768px) {
  .hero__home .body__wrapper {
    padding-top: 0;
  }
}

.hero__home .body__content {
  color: #fff;
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  position: relative;
}

.hero__home .body__content p {
  padding: 1rem 0;
}

.hero__home .body__content a {
  max-width: 10rem;
}

.hero__home .carousel__wrapper {
  z-index: 3;
  max-width: 100%;
  position: absolute;
  overflow: hidden;
  transform: translateY(-100%);
}

@media screen and (width <= 430px) {
  .mobile__hide {
    display: none !important;
  }
}

.static__map {
  object-fit: cover;
  width: 100%;
  max-height: 300px;
}

@media screen and (width <= 768px) {
  .apply_imageholder {
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
  }

  .apply_imageholder img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.applyrole {
  padding: var(--spacing-global);
}

.applyrole__grid {
  column-gap: var(--spacing-global);
  grid-template-columns: 1fr 1fr 150px;
  display: grid;
}

.applyrole__title {
  color: #222;
  padding-bottom: var(--spacing-global);
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
}

.applyrole__description {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
}

.applyrole__description p {
  font-weight: inherit;
}

.applyrole__back {
  margin-left: calc(var(--spacing-global) * -1);
  margin-right: calc(var(--spacing-global) * -1);
  margin-top: calc(var(--spacing-global) * -1);
  margin-bottom: var(--spacing-global);
  background: var(--color-brand);
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.applyrole__back a {
  color: #fff;
  font-size: 1.225rem;
  font-weight: 400;
  display: flex;
}

.applyrole__actions {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  transition: opacity .3s ease-in-out, transform .15s ease-in-out;
  display: flex;
  transform: scale(.4);
}

.applyrole__actions--visible {
  opacity: 1;
  pointer-events: initial;
  transform: scale(1);
}

.applyrole__points {
  flex-wrap: wrap;
  align-self: flex-start;
  gap: 2rem;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 10rem;
}

.applyrole__points .points__item {
  text-align: right;
  flex: 1 0 40%;
  align-self: flex-start;
}

.applyrole__points .points__item a, .applyrole__points .points__item button {
  pointer-events: all;
}

.applyrole__points .points__item--image {
  flex-basis: 100%;
  padding-bottom: 2rem;
}

.applyrole__points .points__item--apply a {
  max-width: 50%;
  display: inline-block;
}

.applyrole__points .points__header {
  margin: 0;
  padding-bottom: .5rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.applyrole__points .points__text {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

.apply__loader {
  justify-content: center;
  display: flex;
}

@media screen and (width <= 3090px) {
  .applyrole__title {
    text-align: center;
    font-size: 2.8rem;
  }

  .applyrole__grid {
    flex-direction: column;
    display: flex;
  }

  .applyrole__actions {
    visibility: initial;
  }

  .applyrole__points {
    z-index: 0;
    background: #fff;
    padding: 0 0 1rem;
    position: relative;
    top: 0;
  }

  .applyrole__points .points__item {
    text-align: left;
    flex: 1 0 40%;
  }
}

.points__item.points__item--image {
  flex-basis: 100%;
}

.points__item.points__item--apply {
  flex-basis: 100%;
  justify-content: flex-end;
  gap: 1rem;
  display: flex;
}

.result {
  max-width: 768px;
  margin: 0 auto;
}

.result .applyrole__points {
  width: 100%;
}

.result .applyrole__points .points__item {
  text-align: center;
}

.points__item.points__item--apply {
  z-index: 7;
  pointer-events: none;
  justify-content: center;
  padding-bottom: 2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.points__item.points__item--apply button, .points__item.points__item--apply a {
  pointer-events: all;
}

.points__item.points__item--apply .brand {
  border-color: #fff;
}

.role__grid {
  max-width: 768px;
  padding-bottom: var(--spacing-global);
  grid-template: "logo logo logo"
                 "hiring salary actions" 1fr
                 "type location actions" 1fr
                 / 1fr 1fr auto;
  gap: 1rem;
  margin: 0 auto;
  display: grid;
}

.role__grid .logo {
  grid-area: logo;
}

.role__grid .actions {
  flex-direction: column;
  grid-area: actions;
  justify-content: space-evenly;
  align-items: flex-end;
  display: flex;
}

.role__grid .hiring {
  grid-area: hiring;
}

.role__grid .salary {
  grid-area: salary;
}

.role__grid .type {
  grid-area: type;
}

.role__grid .location {
  grid-area: location;
}

.role__grid h3 {
  font-size: 1.2rem;
  font-weight: 400;
}

.role__grid p {
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 300;
}

.role__grid .action__button {
  border: solid 1px var(--color-brand);
  background: var(--color-brand);
  box-sizing: border-box;
  color: #fff;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: .2rem 1rem;
  display: flex;
}

.role__grid .action__button span {
  color: #fff;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
}

.role__grid .action__button svg {
  fill: #fff;
}

.privacy, .terms {
  padding: var(--spacing-global);
}

.terms h2 {
  font-size: 3rem;
}

.terms h3 {
  font-size: 2.5rem;
}

.contactform {
  display: block;
}

.contactform label {
  flex-flow: column wrap;
  padding-bottom: 1rem;
  display: flex;
}

.contactform label.label-error {
  color: var(--color-error);
}

.contactform label.label-error span {
  color: inherit;
}

.contactform label.label-error input, .contactform label.label-error textarea {
  border-color: var(--color-error);
}

.contactform label.checkbox {
  flex-direction: row;
  align-items: center;
}

.contactform label.checkbox span {
  padding: 0 0 0 .5rem;
}

.contactform label .error {
  color: var(--color-error);
  flex-basis: 100%;
  padding-top: .5rem;
  font-size: .8rem;
  display: block;
}

.contactform span {
  color: var(--color-link-primary);
  padding-bottom: .25rem;
  font-size: 1rem;
  display: block;
}

.contactform textarea, .contactform input {
  border: solid 1px var(--color-brand);
  border-radius: var(--radius);
  padding: .7rem;
  font-size: 1rem;
  display: block;
}

.loader-wrapper {
  justify-content: center;
  display: flex;
}

.accordion {
  border-top: solid 1px var(--color-link-primary);
}

.accordion ul, .accordion li {
  margin: 0;
  padding: 0;
}

.accordion li {
  border-bottom: solid 1px var(--color-link-primary);
}

.accordion .accordion__title {
  justify-content: space-between;
  align-items: center;
  margin: 1.25rem 0;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
}

.accordion__item .accordion__body {
  transition: max-height .4s ease-in-out;
  overflow: hidden;
}

.accordion__item .accordion__body p {
  padding: .5rem 0;
}

.accordion__item--closed .accordion__body {
  max-height: 0;
}

.accordion__item--open .accordion__body {
  max-height: 15rem;
}

.contact {
  padding: var(--spacing-global);
  max-width: 768px;
  margin: 0 auto;
}
/*# sourceMappingURL=index.2ce197b4.css.map */
