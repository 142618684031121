.paginator {
    padding: var(--spacing-global);
    margin: 2rem 0;

    li {
        margin: 0;



        button {
            border: solid 1px var(--color-brand);
            padding: 0.25rem 0.5rem;
            font-size: 1.0rem;
            font-weight: 400;
            cursor: pointer;
            background: white;
            transition: transform 0.1s ease-in-out, background 0.3s linear;

            &:hover {
                background: var(--color-neutral);
                transform: scale(1.1);
            }

            &.current {
                background: var(--color-brand);
                color: white;
            }
        }
    }

    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
    flex: 1 1 0px;
    justify-content: center;
}