ul,
li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.job__list {
    padding: 0 var(--spacing-global);
    max-width: 768px;
    margin: 0 auto
}

.job__item {
    border-bottom: solid 1px #222;
    padding: 0.5rem;
}

.job__row {
    display: grid;
    grid-template-columns: 1fr 150px;
    column-gap: var(--spacing-global);
    align-items: flex-start;
    margin: 2rem 0 1rem 0;
}

.job__row--end {
    align-items: flex-end;

    .brand--small {
        position: relative;
        top: 0.5rem;
        right: -0.5rem;
    }
}

.job__title {
    color: #222;
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 0;
    display: flex;
    align-items: center;
    align-self: center;
}

.job__date {
    background: var(--color-neutral);
    padding: 0.625rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    text-align: center;
}

.job__salary {
    color: #222;
    font-size: 1.0rem;
    font-weight: 400;
    margin-top: 0;
}

.job__description {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    max-height: 4rem;
    text-overflow: ellipsis;
}

.job__info {
    display: grid;
    grid-template-columns: 1fr 1fr 150px;
    padding-bottom: 1rem;

}

.job__location {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 300;
    padding-right: 2rem;

    &--apply {
        display: block;
        padding-right: 0;


    }
}

.brand__alternative {
    margin-bottom: 1rem;
    max-width: 6rem;
}


@media screen and (max-width: 520px) {

    .job__info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    .job__location {
        width: 100%;
        justify-content: flex-start;
    }

    .job__location--apply {
        grid-column: span 2;
        padding-top: 2rem;

        a {
            max-width: none;

        }
    }
}