.static__map {
    width: 100%;
    object-fit: cover;
    max-height: 300px;
}

@media screen and (max-width: 768px) {



    .apply_imageholder {
        position: relative;
        width: 100%;
        /* Or a specific width */

        height: 0;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.applyrole {
    padding: var(--spacing-global);

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr 150px;
        column-gap: var(--spacing-global);
    }

    &__title {
        font-size: 3.5rem;
        font-style: normal;
        font-weight: 700;
        color: #222;
        padding-bottom: var(--spacing-global);

    }

    &__description {
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 300;

        p {
            font-weight: inherit;
        }
    }

    &__back {
        margin-left: calc(var(--spacing-global) * -1);
        margin-right: calc(var(--spacing-global) * -1);
        margin-top: calc(var(--spacing-global) * -1);
        margin-bottom: var(--spacing-global);

        a {

            display: flex;
            font-size: 1.225rem;
            font-weight: 400;
            color: white;
        }

        background: var(--color-brand);
        position: sticky;
        top: 0;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 72px;
    }

    &__actions {
        visibility: hidden;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        opacity: 0;
        transform: scale(0.4);
        pointer-events: none;
        transition: opacity 0.3s ease-in-out, transform 0.15s ease-in-out;

        &--visible {
            opacity: 1;
            pointer-events: initial;
            transform: scale(1);
        }
    }
}

.applyrole__points {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-self: flex-start;
    position: sticky;
    top: 10rem;

    .points__item {
        flex: 1 0 40%;
        align-self: flex-start;
        text-align: right;


        a,
        button {
            pointer-events: all;
        }

        &--image {
            flex-basis: 100%;
            padding-bottom: 2rem;
        }

        &--apply {

            a {
                max-width: 50%;
                display: inline-block;
            }
        }
    }

    .points__header {
        font-size: 1.2rem;
        margin: 0;
        padding-bottom: 0.5rem;
        font-weight: 700;
    }

    .points__text {
        font-size: 1rem;
        margin: 0;
        font-weight: 400;
    }
}

.apply__loader {
    display: flex;
    justify-content: center;
}


@media screen and (max-width: 3090px) {

    .applyrole__title {
        font-size: 2.8rem;
        text-align: center;
    }

    .applyrole__grid {
        display: flex;
        flex-direction: column;


    }

    .applyrole__actions {
        visibility: initial;
    }


    .applyrole__points {
        padding: 0 0 1rem 0;
        background: white;
        z-index: 0;
        top: 0;
        position: relative;

        .points__item {

            text-align: left;
            flex: 1 0 40%;
        }
    }

}

.points__item.points__item--image {
    flex-basis: 100%;
}

.points__item.points__item--apply {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    flex-basis: 100%;
}


.result {
    margin: 0 auto;
    max-width: 768px;

    .applyrole__points {
        width: 100%;
    }

    .applyrole__points .points__item {
        text-align: center;
    }
}

.points__item.points__item--apply {
    position: sticky;
    z-index: 7;
    top: 10px;
    justify-content: center;
    padding-bottom: 2rem;
    pointer-events: none;

    button,
    a {
        pointer-events: all;
    }

    .brand {
        border-color: white;
    }
}


.role__grid {
    display: grid;
    max-width: 768px;
    margin: 0 auto;
    padding-bottom: var(--spacing-global);
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: auto 1fr 1fr;
    gap: 1rem;
    grid-template-areas:
        "logo logo logo"
        "hiring salary actions"
        "type location actions";

    .logo {
        grid-area: logo;
    }

    .actions {
        grid-area: actions;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-end;
    }

    .hiring {
        grid-area: hiring;
    }

    .salary {
        grid-area: salary;
    }

    .type {
        grid-area: type;
    }

    .location {
        grid-area: location;
    }

    h3 {
        font-size: 1.2rem;
        font-weight: 400;
    }

    p {
        font-size: 1.2rem;
        font-weight: 300;
        margin-bottom: 0;
    }

    .action__button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px var(--color-brand);
        background:var(--color-brand);
        padding: 0.2rem 1rem;
        border-radius: 5px;
        box-sizing: border-box;
        color: white;

        span {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            font-weight: 400;
            color: white;
        }

        svg {
            fill: white;
        }
    }
}