.grid {
    padding: var(--spacing-global-double) 30px;
}

.grid__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing-global);
}

.grid__content {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-global) 0;
}

.grid__image{
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/train.jpg');
    background-position: center;
    border-radius: 30px;
}

.grid h2 {
    font-size: 2.5rem;
    margin-top: 0;
}

@media screen and (max-width: 990px) {
    .grid__inner {
      display: block;
    }
    .grid__image{
        min-height: 20rem;
    }
  }