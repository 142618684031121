body {
  margin: 0;
  font-family: 'Roboto';
  font-size: 62.5%;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}



@keyframes slide-out {
  to {
    opacity: 1;
    transform: translateX(-100%);
  }
}

@keyframes slide-in {
  from {
    opacity: 1;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes slide-out-reverse {
  to {
    opacity: 1;
    transform: translateX(100%);
  }
}


@keyframes slide-in-reverse {
  from {
    opacity: 1;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

html::view-transition-old(root) {
  animation: slide-out 0.3s;
}

html::view-transition-new(root) {
  animation: slide-in 0.3s;
}

html.exit::view-transition-old(root) {
  animation: slide-out-reverse 0.3s;
}

html.exit::view-transition-new(root) {
  animation: slide-in-reverse 0.3s;
}



:root {
  --color-brand: #0E682D;
  --color-error: #ab0000;
  --color-brand-alternative: #ffffff;
  --color-link-primary: #222;
  --color-neutral: #EEEEEE;
  --spacing-global-double: 7rem;
  --spacing-global: 4rem;
  --padding-large: 2rem;
  --radius: 10px;
}

a {
  font-size: 1rem;
  text-decoration: none;
}

a,
input,
.job__date,
.input__wrapper,
button {
  border-radius: var(--radius);
}



@media screen and (max-width: 990px) {
  :root {
    --spacing-global: 1.25rem;
    --spacing-global-double: 3rem;
    --padding-large: 0.75Ìrem;
  }

}


.brand {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid var(--color-brand, #0E682D);
  background: var(--color-brand, #0E682D);
  color: var(--color-brand-alternative)
}

.brand.brand--small {
  padding: 0.5rem 1rem;
}

a.brand {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid var(--color-brand, #0E682D);
  background: var(--color-brand, #0E682D);
  color: var(--color-brand-alternative)
}

a.brand__alternative {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #222;
  background: transparent;
  color: #222;
  transition: background 0.3s ease-in-out;
}

.brand__white {
  display: flex;
  font-size: 1rem !important;
  padding: 0.55rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #222;
  background: #fff;
  color: #222 !important;
  transition: background 0.3s ease-in-out;
}

a.brand__alternative:hover {
  background: var(--color-brand, #0E682D);
  color: #ffffff;
  transition: background 0.3s ease-in-out;
}

button.brand {
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid var(--color-brand, #0E682D);
  background: var(--color-brand, #0E682D);
  color: var(--color-brand-alternative)
}

p {
  font-size: 1.125rem;
  font-weight: 300;
}

.f-r {
  display: flex;
  justify-content: flex-end;
}

h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin: 0;
}

h2 {
  font-size: 3.0rem;
  color: var(--color-link-primary);
}

h3 {
  font-size: 2.5rem;
  color: var(--color-link-primary);
}

@media (max-width: 768px) {
  #main-content {
    padding-bottom: 70px;
  }
}


.cookie__inline {
  padding-right: 1rem;
  display: flex;
  gap: 1rem;

  button {
    font-size: 1rem !important;
  }


}

@media (max-width: 768px) {
  .cookie__inline {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    width: 100%;
  }


}