.search {
    padding: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 5px;
    position: sticky;
    background: var(--color-brand-alternative);
    top: 0;
    z-index: 5;

    &__inner {
        grid-template-columns: 1fr 130px;
        display: grid;
        gap: 1rem;
        max-width: 768px;
        margin: 0 auto;
    }

    button {
        font-size: 1rem;
        font-weight: 400;
    }

    &__clear {
        position: absolute;
        right: 167px;
        top: 26px;
        background: var(--color-brand-alternative);
        border: solid 1px var(--color-neutral);
        border-radius: 5px;
        font-size: 0.8rem;
        transition: opacity 0.3s ease;
        border-radius: var(--radius);

        &--hidden {
            pointer-events: none;
            opacity: 0;
        }
    }

}

@media screen and (min-width: 769px) {
    .search {
        &__inner {
            grid-template-columns: 1fr 100px 130px;
        }

        &__clear {
            position: relative;
            right: initial;
            top: inherit;

            &--hidden {
                pointer-events: none;
                opacity: 0.2;
            }
        }
    }
}

@media screen and (min-width: 991px) {
    .search {
        position: sticky;
        top: 0px;
        z-index: 6;
    }
}