.hero__home {
    position: relative;
    border: solid 30px #fff;
    margin-bottom: -30px;
    img {
        height: calc(100vh - (72px + 60px));
        border-radius: 30px;;
        object-fit: cover;
        object-position: center;
        width: 100%;
        position: relative;
        z-index: 1;

        @media (max-width: 768px) {

            height: calc((var(--vh, 1vh) * 100) - 72px);
            height: calc(100vh - 152px);
        }
    }

    .overlay {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        bottom: 2px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 30px;
        /* Black with 50% opacity */
    }

    .body__wrapper {
        position: absolute;
        z-index: 3;
        width: 100%;
        padding-top: 4rem;

        @media (max-width: 768px) {

            padding-top: 0;
        }
    }

    .body__content {
        max-width: 768px;
        position: relative;
        margin: 0 auto;
        color: white;
        text-align: center;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            padding: 1rem 0;
        }

        a {
            max-width: 10rem;
        }
    }

    .carousel__wrapper {
        transform: translateY(-100%);
        position: absolute;
        z-index: 3;
        max-width: 100%;
        overflow: hidden;
    }

}

@media screen and (max-width: 430px) {
    .mobile__hide{
        display: none !important;
    }
}